<!--suppress JSUnresolvedVariable -->
<template>
  <div class="scrollable-modal">
    <modal
      height="600px"
      name="newReservationModal"
      @closed="modalClose"
      @before-open="beforeModalOpen"
    >
      <div class="modal-content">
        <h5 class="text-center bottom-border-separator">
          Foglalás létrehozása
        </h5>
        <div class="row">
          <!-- reservation details -->
          <div class="medium-12 columns">
            <table class="unstriped">
              <tbody>
              <tr>
                <td>Név</td>
                <td>{{ currentGuest.name }}</td>
              </tr>
              <tr>
                <td>Felhasználó típus</td>
                <td>{{ getGuestTypeName(currentGuest.guestType) }}</td>
              </tr>
              <tr>
                <td>Pálya</td>
                <td>{{ getSubjectText(reservation.subjectID) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <!-- reservation details -->
          <div class="medium-6 columns">
            <label for="startTime">Kezdés</label>
            <input
              id="startTime"
              v-model="reservation.startTime"
              type="time"
            >
          </div>
          <div class="medium-6 columns">
            <label for="endTime">Vége</label>
            <input
              id="endTime"
              v-model="reservation.endTime"
              type="time"
            >
          </div>
          <div v-if="isEnabledTimetableExtraOption"
               class="medium-12 columns">
            <h6 class="text-center">Opciók</h6>
            <timetable-extra-options :id="`timetableExtraOptions_${_uid}`"
                                     :extra-options="timetableExtraOptions"/>
          </div>
          <div
            v-show="!isTimeIntervalValid"
            class="medium-12 columns"
          >
            <span style="background: red">hibás intervallum</span>
          </div>
          <!-- add participant switcher -->
          <!--
          <div class="medium-6 columns">
            <span>Résztvevők megjelenítése</span>
            <div class="switch">
              <input class="switch-input"
                     id="isAddParticipants"
                     type="checkbox" name="isAddParticipantsSwitch"
                     v-model="isAddParticipants">
              <label class="switch-paddle" for="isAddParticipants">
                <span class="show-for-sr"></span>
              </label>
            </div>
          </div>
          -->
          <!-- ./add participant switcher -->
          <!-- permanent reservation switcher -->
          <div class="medium-6 columns">
            <span>Állandó foglalás</span>
            <div class="switch">
              <input
                id="isPermanent"
                v-model="isPermanent"
                class="switch-input"
                name="isPermanentSwitch"
                type="checkbox"
              >
              <label
                class="switch-paddle"
                for="isPermanent"
              >
                <span class="show-for-sr"/>
              </label>
            </div>
          </div>
          <!-- ./permanent reservation switcher -->
          <!-- is club switcher -->
          <div class="medium-6 columns">
            <span>Klub foglalás</span>
            <div class="switch">
              <input
                id="isClub"
                v-model="reservation.isClub"
                class="switch-input"
                name="isPermanentSwitch"
                type="checkbox"
              >
              <label
                class="switch-paddle"
                for="isClub"
              >
                <span class="show-for-sr"/>
              </label>
            </div>
          </div>
          <!-- ./is club switcher -->
          <!-- participants -->
          <div
            v-show="isAddParticipants"
            class="medium-12 columns participants"
          >
            <add-reservation-participant :participants="[]"/>
          </div>
          <!-- participants -->
          <!-- permanent reservation -->
          <div
            v-show="isPermanent"
            class="medium-12 columns permanent"
          >
            <div class="date-container">
              <label for="permanentEndDate">Végső dátum</label>
              <date-picker
                id="permanentEndDate"
                v-model="reservation.endDate"
                :calendar-button="true"
                :monday-first="true"
                calendar-button-icon="fi-calendar"
                format="yyyy. MM. dd."
                language="hu"
              />
            </div>
            <span
              v-if="!isPermanentDateValid"
              class="form-error is-visible"
            >
              Érvényes jövőbeli dátum megadása kötelező!
            </span>
          </div>
          <!-- ./permanent reservation -->
          <!-- history table -->
          <div
            v-if="isShowHistoryTable"
            class="medium-12 columns"
          >
            <h5 class="text-center bottom-border-separator">
              Pálya történet
            </h5>
            <time-block-history-table v-if="hasHistoryTable"/>
            <div
              v-else
              class="callout warning"
            >
              Nincs adat az időpontról
            </div>
          </div>
          <!-- ./history table -->
          <!-- action btn -->
          <div class="medium-12 columns text-right top-border-separator">
            <button
              v-if="hasSeason"
              class="button warning"
              @click="seasonReservation()"
            >
              Szezon foglalás
            </button>
            <button
              class="button"
              @click="sendReservation()"
            >
              Foglalás létrehozása
            </button>
            <button
              class="button secondary"
              @click="showHistoryTable()"
            >
              Pálya történet megjelenítése
            </button>
          </div>
          <!-- ./action btn -->
        </div>
      </div>
      <button
        aria-label="Close modal"
        class="close-button"
        data-close
        type="button"
        @click="closeModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </modal>
  </div>
</template>

<script>
import moment from 'moment'
import AddReservationParticipant from './AddReservationParticipant.vue'
import TimeBlockHistoryTable from './TimeBlockHistoryTable.vue'
import TimetableExtraOptions from '@/components/views/reservation/components/schedule/components/TimetableExtraOptions';
import DatePicker from 'vuejs-datepicker'
import {getCookie} from '../../../../../../processors/cookie'
import errorHandle from '@/processors/errorHandle'
import notify from '@/processors/notify'
import util from '@/processors/util'

export default {
  data () {
    return {
      isAddParticipants: false,
      isPermanent: false,
      reservation: {
        status: 'free',
        startTime: '00:00',
        endTime: '00:00',
        length: 0,
        subjectID: null,
        date: null,
        endDate: null,
        isClub: false,
      },
      isShowHistoryTable: false,
    }
  },
  computed: {
    hasHistoryTable () {
      const historyTable = this.$store.state.historyTable
      return historyTable.length > 0
    },
    currentGuest () {
      return this.$store.state.guest.current
    },
    isSelectedGuest () {
      return this.$store.state.guest.isSelected
    },
    isTimeIntervalValid () {
      let isValid = false
      const startHour = Number(this.reservation.startTime.slice(
        0, this.reservation.startTime.indexOf(':')))
      const startMin = Number(this.reservation.startTime.slice(
        this.reservation.startTime.indexOf(':') + 1, this.reservation.startTime.length))
      const endHour = Number(this.reservation.endTime.slice(
        0, this.reservation.endTime.indexOf(':')))
      const endMin = Number(this.reservation.endTime.slice(
        this.reservation.endTime.indexOf(':') + 1, this.reservation.endTime.length))

      if (startHour < endHour) {
        isValid = true
      } else if (startHour === endHour) {
        if (startMin < endMin) {
          isValid = true
        }
      }
      return isValid
    },
    isPermanentDateValid () {
      /* eslint-disable */
      let isValid = false
      let curResDate = this.reservation.date
      let permanentEndDate = this.reservation.endDate
      let isPermanent = this.isPermanent
      if (isPermanent) {
        if (moment(permanentEndDate).isAfter(curResDate)) {
          isValid = true
        }
      } else {
        isValid = true
      }
      return isValid
    },
    hasSeason () {
      let seasons = this.$store.state.availableSeasons
      let availableItems = this.$store.state.availableItems
      let currentGuestType = this.$store.state.guest.current.guestType
      let date = moment(this.reservation.date)
      if (seasons) {
        if (seasons.length > 0) {
          if (availableItems.hasOwnProperty(currentGuestType)) {
            if (availableItems[currentGuestType].hasOwnProperty('season_pass')) {
              for (let season of seasons) {
                if (date.isAfter(moment(season.canPurchaseFrom)) && date.isBefore(moment(season.canPurchaseTo))) {
                  return true
                }
              }
            }
          }
        }
      }
      return false
    },
    isTimeValidForSeason () {
      // TODO: Ha invalid a data a szezon foglaláshoz akkor legyen inaktív a gomb
    },
    isEnabledTimetableExtraOption () {
      return this.$store.getters.isEnabledTimetableExtraOption
    },
    timetableExtraOptions () {
      return this.$store.getters.getTimetableExtraOptionsBySubjectType(this.$store.state.table.currentSubjectTypeID)
    },
  },
  methods: {
    showHistoryTable () {
      const subjectID = this.reservation.subjectID
      const startDate = '' + this.reservation.date + ' ' + this.reservation.startTime

      this.$store.dispatch('fetchTimeBlockHistory', { subjectID: subjectID, startDate: startDate }).then(() => {
        this.isShowHistoryTable = true
      })
    },
    getSubjectText (subjectID) {
      const subjectTypes = this.$store.state.subjectTypes
      let subjectText = ''
      if (subjectID) {
        for (let i = 0; i < subjectTypes.length; i++) {
          const subjectType = subjectTypes[i]
          for (let j = 0; j < subjectType.subjects.length; j++) {
            const subject = subjectType.subjects[j]
            if (Number(subjectID) === Number(subject.ID)) {
              subjectText = '' + subjectType.name + ' ' + subject.name
              break
            }
          }
        }
      }
      return subjectText
    },
    getGuestTypeName: util.getGuestTypeName,
    beforeModalOpen (event) {
      this.reservation.status = event.params.reservation.status
      this.reservation.startTime = moment(event.params.reservation.startTime).format('HH:mm')
      this.reservation.endTime = moment(event.params.reservation.endTime).format('HH:mm')
      this.reservation.length = event.params.reservation.length
      this.reservation.subjectID = event.params.reservation.subjectID
      this.reservation.date = moment(event.params.reservation.startTime).format('YYYY-MM-DD')
      this.reservation.endDate = moment(event.params.reservation.startTime).format('YYYY-MM-DD')
    },
    modalClose () {
      this.reservation = {
        status: 'free',
        startTime: '00:00',
        endTime: '00:00',
        length: 0,
        subjectID: null,
        date: null,
        endDate: null,
        isClub: false,
      }
      this.isAddParticipants = false
      this.isPermanent = false
      this.isShowHistoryTable = false
    },
    closeModal () {
      this.$modal.hide('newReservationModal')
    },
    sendReservation () {
      // send only if all data is valid
      if (this.isTimeIntervalValid && this.isPermanentDateValid) {
        const startTime = this.reservation.startTime
        const endTime = this.reservation.endTime
        const resStartTime = moment(this.reservation.date).hours(startTime.substring(0, startTime.indexOf(':')))
          .minutes(startTime.substring(startTime.indexOf(':') + 1), startTime.lenght)
        const resEndTime = moment(this.reservation.date).hours(endTime.substring(0, endTime.indexOf(':')))
          .minutes(endTime.substring(endTime.indexOf(':') + 1), endTime.lenght)
        const resPermEndDate = moment(this.reservation.endDate).format('YYYY-MM-DD')
        const participants = []

        const curParticipantsSelect = document.getElementById('currentParticipants')
        const options = curParticipantsSelect && curParticipantsSelect.options
        for (let i = 0; i < options.length; i++) {
          const opt = options[i]
          participants.push({ guestID: opt.value })
        }


        const reservation = {
          guestID: this.$store.state.guest.current.ID,
          guestName: this.$store.state.guest.current.name,
          email: this.$store.state.guest.current.email,
          invokerID: this.$store.state.admin.ID,
          isAdmin: true,
          participants: participants,
          reservationDate: this.reservation.date,
          start: resStartTime,
          end: resEndTime,
          subjectID: this.reservation.subjectID,
          isPermanent: this.isPermanent,
          endDate: resPermEndDate,
          isClub: this.reservation.isClub,
        }

        // Extra options
        if (this.isEnabledTimetableExtraOption) {
          const extraOptionInputs = document.getElementById(`timetableExtraOptions_${this._uid}`).getElementsByTagName('input')
          const selectedExtraOptionIDs = []
          for (let extraOptionInput of extraOptionInputs) {
            if (extraOptionInput.checked)
              selectedExtraOptionIDs.push(extraOptionInput.dataset.optionId)
          }
          reservation.timetableExtraOptions = selectedExtraOptionIDs
        }
        // ./ Extra options

        console.log('--- reservation data: ')
        console.log(reservation)

        this.$http({
          url: this.$store.state.url.reservation,
          method: 'POST',
          params: { userID: getCookie('userID'), token: getCookie('token') },
          body: JSON.stringify({ data: reservation }),
        }).then((response) => {
          console.log('new reservation success')
          this.$modal.hide('newReservationModal')
          notify.success(response.body.msg)
        })
          .catch(error => {
            errorHandle.httpError(error)
          })
      } else {
        alert('some data invalid')
      }
    },
    seasonReservation () {
      if (this.isTimeIntervalValid && this.isPermanentDateValid) {
        const startTime = this.reservation.startTime
        const endTime = this.reservation.endTime
        const resStartTime = moment(this.reservation.date).hours(startTime.substring(0, startTime.indexOf(':')))
          .minutes(startTime.substring(startTime.indexOf(':') + 1), startTime.lenght)
        const resEndTime = moment(this.reservation.date).hours(endTime.substring(0, endTime.indexOf(':')))
          .minutes(endTime.substring(endTime.indexOf(':') + 1), endTime.lenght)
        const participants = []

        const curParticipantsSelect = document.getElementById('currentParticipants')
        const options = curParticipantsSelect && curParticipantsSelect.options
        for (let i = 0; i < options.length; i++) {
          const opt = options[i]
          participants.push({ guestID: opt.value })
        }

        const reservation = {
          guestID: this.$store.state.guest.current.ID,
          guestName: this.$store.state.guest.current.name,
          email: this.$store.state.guest.current.email,
          invokerID: this.$store.state.admin.ID,
          isAdmin: true,
          reservationDate: this.reservation.date,
          start: moment(resStartTime).format('YYYY-MM-DD HH:mm'),
          end: moment(resEndTime).format('YYYY-MM-DD HH:mm'),
          subjectID: this.reservation.subjectID,
        }

        console.log('--- season reservation data: ')
        console.log(reservation)

        this.$modal.hide('newReservationModal')
        this.$modal.show('userPurchaseModal',
          {
            isTicket: false,
            isOtherItemBuy: true,
            isPassBuy: false,
            isClub: false,
            isSeasonBuy: true,
            reservation: reservation,
          },
        )
      } else {
        alert('some data invalid')
      }
    },
  },
  components: {
    AddReservationParticipant,
    TimeBlockHistoryTable,
    DatePicker,
    TimetableExtraOptions,
  },
}
</script>

<style scoped>
.callout {
  padding: 1rem;
}
</style>
